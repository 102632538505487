import React, { useState, useEffect, useCallback } from 'react';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';

const mapContainerStyle = {
  width: '100%',
  height: '400px',
};

const defaultCenter = {
  lat: 28.6139,
  lng: 77.209,
};

const MapPicker = ({ onLocationSelect, onClose }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBMu1yUALW9rF8NcfDWLQs8rPqpm8EjMQQ',
  });

  const [center, setCenter] = useState(defaultCenter);
  const [marker, setMarker] = useState(null);
  const [address, setAddress] = useState('');
  const [isLocationSelected, setIsLocationSelected] = useState(false);

  // Get user's current location on load
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLatLng = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setCenter(userLatLng);
          setMarker(userLatLng);
        },
        (error) => {
          console.error('Error fetching the current location:', error);
          alert('Could not fetch location. Please ensure location services are enabled.');
        },
        { enableHighAccuracy: true, timeout: 5000 }
      );
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  }, []);

  const getAddressFromCoordinates = async (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    const { results } = await geocoder.geocode({ location: { lat, lng } });
    if (results[0]) {
      return results[0].formatted_address;
    } else {
      return 'No address found';
    }
  };

  const onMapClick = useCallback(async (event) => {
    const location = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    setMarker(location);
    setIsLocationSelected(true);

    const fetchedAddress = await getAddressFromCoordinates(location.lat, location.lng);
    setAddress(fetchedAddress);

    onLocationSelect([location.lat, location.lng, fetchedAddress]);
  }, [onLocationSelect]);

  // Handle confirm location
  const handleConfirmLocation = () => {
    onClose();
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div style={{ position: 'relative', margin: '0 auto', maxWidth: '800px', zIndex: 1000 }}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={15}  // Adjust zoom level if necessary
        center={center}
        onClick={onMapClick}
        options={{ disableDefaultUI: true }}
      >
        {marker && <Marker position={marker} />}
      </GoogleMap>

      {isLocationSelected && (
        <div style={{
          position: 'absolute',
          bottom: '20px',
          left: '20px',
          zIndex: 1000,
          backgroundColor: 'white',
          padding: '15px',
          borderRadius: '8px',
          boxShadow: '0 2px 5px rgba(0,0,0,0.3)',
          width: 'calc(100% - 40px)'
        }}>
          <p style={{ margin: '0 0 10px', fontWeight: 'bold', fontSize:'12px'}}>Address: {address}</p>
          <button
            onClick={handleConfirmLocation}
            style={{
              padding: '10px 20px',
              backgroundColor: '#5c9a41',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              fontSize: '16px',
              zIndex: 1000
            }}
          >
            Confirm Location
          </button>
        </div>
      )}
    </div>
  );
};

export default MapPicker;