import React, { useEffect, useState } from 'react';
import './main.css';
import logo from './assets/images/logo2.png';
import user from './assets/images/login-user.png';
import pass from './assets/images/login-pass.png';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import LoginImg from './assets/images/LoginImg.png';
import axiosURL from './config';

function LoginPage({ onLogin }) {
  const [userType, setUserType] = useState('doctor');
  const [username, setUsername] = useState('');
  const [otp, setOtp] = useState('');
  const [otpRequested, setOtpRequested] = useState(false);
  const [error, setError] = useState('');
  const [errors, setErrors] = useState({});
  const [timer, setTimer] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    let countdown;
    if (timer > 0) {
      countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(countdown);
  }, [timer]);

  const handleTabChange = (newValue) => {
    setUserType(newValue);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberPattern = /^[0-9]{10}$/;
    return phoneNumberPattern.test(phoneNumber);
  };

  const validateOtp = (otp) => {
    const otpPattern = /^[0-9]{6}$/;
    return otpPattern.test(otp);
  };

  const handleRequestOtp = async () => {
    setError('');
    setErrors({});

    if (!validatePhoneNumber(username)) {
      setErrors((prev) => ({ ...prev, phoneNumber: 'Please enter a valid 10-digit phone number' }));
      return;
    }

    try {
      const response = await axiosURL.post('docter/login', { phoneNumber: username });

      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'OTP Sent',
          text: 'An OTP has been sent to your phone number.',
        });
        setOtpRequested(true);
        setTimer(60);
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      setError(error.response.data.validations);
    }
  };

  const handleResendOtp = async () => {
    if (timer === 0) {
      await handleRequestOtp();
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setErrors({});

    if (!validateOtp(otp)) {
      setErrors((prev) => ({ ...prev, otp: 'Please enter a valid 6-digit OTP' }));
      return;
    }

    let redirectUrl = '/';

    redirectUrl = '/doctor-dashboard/dashboard';

    const payload = {
      phoneNumber: username,
      otp
    };

    try {
      const response = await axiosURL.post('docter/login', payload);
      const data = response.data;

      if (response.status === 200) {
        if (data.status === 'pending') {
          Swal.fire({
            icon: 'warning',
            title: 'Pending Verification',
            text: 'Your profile is pending for verification.',
          });
          return;
        }
        localStorage.setItem('docId', data.user.id);
        onLogin(true, userType);
        navigate(redirectUrl);
      } else {
        const validationErrors = data.validations || [];
        const formattedErrors = validationErrors.reduce((acc, error) => {
          acc[error.key] = error.message;
          return acc;
        }, {});
        setErrors(formattedErrors);
        setError(data.message);
      }
    } catch (error) {
      const validationErrors = error.response.data.validations || [];
      const formattedErrors = validationErrors.reduce((acc, error) => {
        acc[error.key] = error.message;
        return acc;
      }, {});
      setErrors(formattedErrors);
      setError(error.response.data.message);
      Swal.fire({
        icon: 'error',
        title: error.response.data.status,
        text: error.response.data.message,
      });
    }
  };

  return (
    <div className='main'>
      <div className='container'>
        <div className='login-container'>
          <img src={logo} alt='Logo' className='logo' />
          <h2>Sign In</h2>
          <p style={{ color: "#00000099", marginBottom: '2rem' }}>
            Enter your log in credentials below to sign in
          </p>
          <div className='capsule-selector'>
            <div
              className={`capsule ${userType === 'doctor' ? 'active' : ''}`}
              onClick={() => handleTabChange('doctor')}
            >
              Doctor
            </div>
            <div
              className={`capsule ${userType === 'medical' ? 'active' : ''}`}
              onClick={() => handleTabChange('medical')}
            >
              Medical
            </div>
          </div>
          {userType == 'doctor' && (
            <div style={{ width: '100%' }}>
              <div className="input-group">
                <div className="image-label-inline">
                  <img src={user} alt="user" className="user" />
                  <h3>Username</h3>
                </div>
                <div className="input-container">
                  <input
                    type="text"
                    placeholder="Enter Your Phone Number"
                    value={username}
                    maxLength={10}
                    minLength={10}
                    onChange={(e) => setUsername(e.target.value)}
                    disabled={otpRequested}
                    style={{ cursor: otpRequested ? 'not-allowed' : 'text' }}
                  />
                </div>
                {errors.phoneNumber && <p className='error'>{errors.phoneNumber}</p>}
              </div>
              {otpRequested && (
                <div className='input-group'>
                  <div className='image-label-inline'>
                    <img src={pass} alt="user" className="user" />
                    <h3>OTP</h3>
                  </div>
                  <div className='input-container'>
                    <input
                      type="text"
                      placeholder="Enter OTP"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                    />
                  </div>
                  {errors.otp && <p className='error'>{errors.otp}</p>}
                </div>
              )}

              {error && <p className='error'>{error}</p>}

              {!otpRequested ? (
                <div className='login-button-container'>
                  <button type="button" className='login-button' onClick={handleRequestOtp}>Request OTP</button>
                </div>
              ) : (
                <div className='login-button-container'>
                  <button type="submit" className='login-button' onClick={handleLogin}>Login</button>
                  <div
                    className={`resend-otp-text ${timer > 0 ? 'disabled' : ''}`}
                    onClick={handleResendOtp}
                    style={{ cursor: timer > 0 ? 'not-allowed' : 'pointer', color: timer > 0 ? '#cccccc' : '#5c9a41', display: 'flex', justifyContent: 'flex-end' }}
                  >
                    Resend OTP {timer > 0 && `(${timer}s)`}
                  </div>
                </div>
              )}
              <div className='link-container'>
                <hr className='custom-hr' />
              </div>
            </div>
          )}
          {userType == 'medical' && (
            <div style={{ width: '100%', alignItems: 'center' }}>
              <button
                type="button"
                className='login-button pharma-button'
                onClick={() => window.location.href = 'https://www.pharma.immplus.in/login'}
                style={{ marginTop: '1rem' }}
              >
                Go to Pharma Login
              </button>
            </div>
          )}
          <div className='login-signup-container'>
            <p>Don't have an account? <a href='/register' className='signup-link'>Sign Up</a></p>
          </div>
        </div>
        <div className='image-container'>
          <img src={LoginImg} alt="Uploaded Preview" className='login-image' />
        </div>
      </div>
    </div>
  );
}

export default LoginPage;