import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './doctor.css';
import { Autocomplete, TextField, CircularProgress, FormHelperText } from '@mui/material';
import Swal from 'sweetalert2';
import axiosURL from '../config';

function Profile() {
    const [doctorDetails, setDoctorDetails] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [docformData, setDocformData] = useState({});
    const [errors, setErrors] = useState({});
    const [doctorImage, setDoctorImage] = useState(null);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [loadingSpecialties, setLoadingSpecialties] = useState(true);
    const [loadingTypes, setLoadingTypes] = useState(true);
    const [isOtherSelected, setIsOtherSelected] = useState(false);
    const [otherSpecialist, setOtherSpecialist] = useState('');
    const [workingHours, setWorkingHours] = useState([]);
    const [selectedHours, setSelectedHours] = useState([]);
    const [loading, setLoading] = useState(true);
    const [specialties, setSpecialties] = useState([]);
    const [types, setTypes] = useState([]);

    useEffect(() => {
        const fetchDoctorDetails = async () => {
            try {
                const doctorId = localStorage.getItem('docId');
                if (!doctorId) {
                    console.error('No doctor ID found in local storage');
                    return;
                }
                const response = await axiosURL.get(`docter/getById?id=${doctorId}`);
                const details = response.data[0];
                setDoctorDetails(details);
                setDocformData({
                    name: details.name,
                    email: details.email,
                    password: '',
                    hospital: details.hospital,
                    about: details.about,
                    type: details.type,
                    patients: details.patients,
                    experience: details.experience,
                    rating: details.rating,
                    specialist: details.specialist,
                    videoFee: details.videoFee,
                    appointmentFee: details.appointmentFee,
                    workinghours: details.workinghours,
                    bankName: details.bankName,
                    ifscCode: details.ifscCode,
                    accountNumber: details.accountNumber,
                    accountHolderName: details.accountHolderName,
                });
            } catch (error) {
                console.error('Error fetching doctor details:', error);
            }
        };

        fetchDoctorDetails();
    }, []);

    useEffect(() => {
        const fetchSpecialties = async () => {
            try {
                const response = await axios.get('https://immuneapi-production.up.railway.app/docterspec/records');
                if (response.status === 200) {
                    setSpecialties(response.data || []);
                } else {
                    console.error('Failed to fetch specialties');
                    setSpecialties([]);
                }
            } catch (error) {
                console.error('Error fetching specialties:', error);
                setSpecialties([]);
            } finally {
                setLoadingSpecialties(false);
            }
        };

        const fetchTypes = async () => {
            try {
                const response = await axios.get('https://immuneapi-production.up.railway.app/typeOfTreatment/records');
                if (response.status === 200) {
                    setTypes(response.data || []);
                } else {
                    console.error('Failed to fetch types');
                    setTypes([]);
                }
            } catch (error) {
                console.error('Error fetching types:', error);
                setTypes([]);
            } finally {
                setLoadingTypes(false);
            }
        };
        const fetchWorkingHours = async () => {
            try {
                const response = await fetch('https://immuneapi-production.up.railway.app/workinghours/records');
                const data = await response.json();
                setWorkingHours(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching working hours:', error);
                setErrors({ fetch: 'Failed to load working hours.' });
                setLoading(false);
            }
        };

        fetchWorkingHours();
        fetchSpecialties();
        fetchTypes();
    }, []);

    const handleSelectionChange = (event, newValue) => {
        setSelectedHours(newValue);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'specialist') {
            if (value === 'Other') {
                setIsOtherSelected(true);
                setDocformData((prevData) => ({
                    ...prevData,
                    [name]: value,
                }));
            } else {
                setIsOtherSelected(false);
                setDocformData((prevData) => ({
                    ...prevData,
                    [name]: value,
                }));
            }
        } else if (name === 'otherSpecialist') {
            setOtherSpecialist(value); // Update the custom specialty value
        } else {
            setDocformData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleImageChange = (e) => {
        setDoctorImage(e.target.files[0]);
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleSave = async () => {
        const doctorId = localStorage.getItem('docId');
        if (!doctorId) {
            console.error('No doctor ID found in local storage');
            return;
        }
    
        const finalSpecialist = isOtherSelected ? otherSpecialist : docformData.specialist;
    
        setDocformData(prevData => ({
            ...prevData,
            specialist: finalSpecialist
        }));
    
        const formData = new FormData();
    
        if (doctorImage) {
            formData.append('img', doctorImage);
        }

        if (selectedHours.length > 0) {
            const workingHoursString = selectedHours.map(hour => hour.name).join(',');
            formData.append('workinghours', workingHoursString);
        }
    
        Object.keys(docformData).forEach(key => {
            formData.append(key, docformData[key]);
        });
    
        formData.append('id', doctorId);
    
        try {
            const response = await axiosURL.post('docter/update', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            if (response.status === 200) {
                Swal.fire({
                    title: 'Success!',
                    text: 'Doctor details updated successfully.',
                    icon: 'success',
                    timer: 1500,
                    confirmButtonText: 'OK'
                });
                setDoctorDetails(prevDetails => ({
                    ...prevDetails,
                    ...docformData
                }));
                setIsEditing(false);
            } else {
                const validationErrors = response.data.validations || [];
                const formattedErrors = validationErrors.reduce((acc, error) => {
                    acc[error.key] = error.message;
                    return acc;
                }, {});
                setErrors(formattedErrors);
            }
        } catch (error) {
            console.error('Error updating doctor details:', error);
            Swal.fire({
                title: 'Error!',
                text: 'Failed to update doctor details. Please try again.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
    };

    const renderDoctorStep1 = () => (
        <>
            <div className="register-group">
                <label>Doctor Image</label>
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    id="doctor-image-upload"
                />
                <label htmlFor="doctor-image-upload" className="custom-file-upload">
                    Choose File
                </label>
                {doctorImage && <img src={URL.createObjectURL(doctorImage)} alt="Doctor Preview" className="image-preview" />}
                {errors.doctorImage && <span className="error">{errors.doctorImage}</span>}
            </div>

            <div className="form-group">
                <label>Name</label>
                <input
                    type="text"
                    name="name"
                    value={docformData.name}
                    placeholder='Enter Your Name'
                    onChange={handleInputChange}
                />
                {errors.name && <span className="error-message">{errors.name}</span>}
            </div>

            <div className="form-group password-group">
                <label>Password</label>
                <input
                    type={passwordVisible ? 'text' : 'password'}
                    name="password"
                    value={docformData.password}
                    onChange={handleInputChange}
                    placeholder='Enter Password'
                />
                {errors.password && <span className="error-message">{errors.password}</span>}
            </div>

            <div className="form-group">
                <label>Email</label>
                <input
                    type="email"
                    name="email"
                    value={docformData.email}
                    placeholder='Enter Your Email'
                    onChange={handleInputChange}
                />
                {errors.email && <span className="error-message">{errors.email}</span>}
            </div>

            <div className="form-group">
                <label>Hospital</label>
                <input
                    type="text"
                    name="hospital"
                    value={docformData.hospital}
                    placeholder='Enter Your Hospital'
                    onChange={handleInputChange}
                />
                {errors.hospital && <span className="error-message">{errors.hospital}</span>}
            </div>

            <div className="form-group">
                <label>About</label>
                <textarea
                    name="about"
                    value={docformData.about}
                    placeholder='Describe your experience and specialties'
                    onChange={handleInputChange}
                />
                {errors.about && <span className="error-message">{errors.about}</span>}
            </div>

            <div className="register-group">
                <label>Type</label>
                {loadingTypes ? (
                    <p>Loading types...</p>
                ) : (
                    <select
                        name="type"
                        value={docformData.type}
                        onChange={handleInputChange}
                    >
                        <option value="" disabled>Select Your Type</option>
                        {types.map((type) => (
                            <option key={type._id} value={type.name}>
                                {type.name}
                            </option>
                        ))}
                    </select>
                )}
                {errors.type && <span className="error">{errors.type}</span>}
            </div>

            <div className="form-group">
                <label>Patients</label>
                <input
                    type="text"
                    name="patients"
                    value={docformData.patients}
                    placeholder='How many patients have you handled'
                    onChange={handleInputChange}
                />
                {errors.patients && <span className="error-message">{errors.patients}</span>}
            </div>

            <div className="form-group">
                <label>Experience</label>
                <input
                    type="text"
                    name="experience"
                    value={docformData.experience}
                    placeholder='Enter Your Experience'
                    onChange={handleInputChange}
                />
                {errors.experience && <span className="error-message">{errors.experience}</span>}
            </div>
        </>
    );

    const renderDoctorStep2 = () => (
        <>
            <div className="form-group">
                <label>Rating</label>
                <input
                    type="text"
                    name="rating"
                    value={docformData.rating}
                    placeholder='Enter Your rating'
                    onChange={handleInputChange}
                />
                {errors.rating && <span className="error-message">{errors.rating}</span>}
            </div>
            <div className="register-group">
                <label>Specialist</label>
                {loadingSpecialties ? (
                    <p>Loading specialties...</p>
                ) : (
                    <select
                        name="specialist"
                        value={docformData.specialist}
                        onChange={handleInputChange}
                    >
                        <option value="" disabled>Select Your Speciality</option>
                        {specialties.map((specialty) => (
                            <option key={specialty._id} value={specialty.name}>
                                {specialty.name}
                            </option>
                        ))}
                        <option value="Other">Other</option>
                    </select>
                )}
                {errors.specialist && <span className="error">{errors.specialist}</span>}
            </div>

            {isOtherSelected && (
                <div className="register-group">
                    <label>Other Specialty</label>
                    <input
                        type="text"
                        name="otherSpecialist"
                        value={otherSpecialist}
                        placeholder='Enter Your Specialty'
                        onChange={handleInputChange}
                    />
                    {errors.specialist && <span className="error">{errors.specialist}</span>}
                </div>
            )}

            <div className="form-group">
                <label>Video Fee</label>
                <input
                    type="text"
                    name="videoFee"
                    value={docformData.videoFee}
                    placeholder='Enter Your Video Consultation Fee'
                    onChange={handleInputChange}
                />
                {errors.videoFee && <span className="error-message">{errors.videoFee}</span>}
            </div>
            <div className="form-group">
                <label>Appointment Fee</label>
                <input
                    type="text"
                    name="appointmentFee"
                    value={docformData.appointmentFee}
                    placeholder='Enter Your Appointment Fee'
                    onChange={handleInputChange}
                />
                {errors.appointmentFee && <span className="error-message">{errors.appointmentFee}</span>}
            </div>
            <div className="register-group">
                <label>Working Hours</label>
                {loading ? (
                    <div><CircularProgress /></div>
                ) : (
                    <Autocomplete
                        multiple
                        options={workingHours}
                        getOptionLabel={(option) => option.name}
                        value={selectedHours}
                        onChange={handleSelectionChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select Working Hours"
                                placeholder="Select..."
                            />
                        )}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                    />
                )}
                {errors.workinghours && <FormHelperText error>{errors.workinghours}</FormHelperText>}
            </div>
        </>
    );

    const renderDoctorStep3 = () => (
        <>
            <div className="form-group">
                <label>Bank Account Name</label>
                <input
                    type="text"
                    name="bankName"
                    value={docformData.bankName}
                    placeholder='Enter Your Bank Account Name'
                    onChange={handleInputChange}
                />
                {errors.bankName && <span className="error-message">{errors.bankName}</span>}
            </div>
            <div className="form-group">
                <label>IFSC Code</label>
                <input
                    type="text"
                    name="ifscCode"
                    value={docformData.ifscCode}
                    placeholder='Enter Your IFSC Code'
                    onChange={handleInputChange}
                />
                {errors.ifscCode && <span className="error-message">{errors.ifscCode}</span>}
            </div>
            <div className="form-group">
                <label>Account Number</label>
                <input
                    type="text"
                    name="accountNumber"
                    value={docformData.accountNumber}
                    placeholder='Enter Your Account Number'
                    onChange={handleInputChange}
                />
                {errors.accountNumber && <span className="error-message">{errors.accountNumber}</span>}
            </div>
            <div className="form-group">
                <label>Account Holder Name</label>
                <input
                    type="text"
                    name="accountHolderName"
                    value={docformData.accountHolderName}
                    placeholder='Enter Your Account Holder Name'
                    onChange={handleInputChange}
                />
                {errors.accountHolderName && <span className="error-message">{errors.accountHolderName}</span>}
            </div>
        </>
    );

    return (
        <div className="profile-container">
            <div className="profile-header">
                <h2>{isEditing ? 'Edit Profile' : 'Profile Details'}</h2>
            </div>
            <div className="profile-body">
                {isEditing ? (
                    <>
                        <div className="form-steps">
                            {currentStep === 1 && renderDoctorStep1()}
                            {currentStep === 2 && renderDoctorStep2()}
                            {currentStep === 3 && renderDoctorStep3()}
                        </div>
                        <div className="form-navigation">
                            <button
                                type="button"
                                className="nav-button"
                                onClick={() => setCurrentStep(currentStep - 1)}
                                disabled={currentStep === 1}
                            >
                                Previous
                            </button>
                            <button
                                type="button"
                                className="nav-button"
                                onClick={() => setCurrentStep(currentStep + 1)}
                                disabled={currentStep === 3}
                            >
                                Next
                            </button>
                            <button
                                type="button"
                                className="cancel-button"
                                onClick={handleCancel}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="save-button"
                                onClick={handleSave}
                            >
                                Save
                            </button>
                        </div>
                    </>
                ) : (
                    <div className="profile-info">
                        <img
                            src={`https://immuneapi-production-api.up.railway.app/${doctorDetails.img}` || 'default-profile.jpg'}
                            alt="Doctor"
                            className="profile-image"
                        />
                        <p><strong>Name:</strong> {doctorDetails.name}</p>
                        <p><strong>Email:</strong> {doctorDetails.email}</p>
                        <p><strong>Hospital:</strong> {doctorDetails.hospital}</p>
                        <p><strong>About:</strong> {doctorDetails.about}</p>
                        <p><strong>Type:</strong> {doctorDetails.type}</p>
                        <p><strong>Patients:</strong> {doctorDetails.patients}</p>
                        <p><strong>Experience:</strong> {doctorDetails.experience}</p>
                        <p><strong>Rating:</strong> {doctorDetails.rating}</p>
                        <p><strong>Specialist:</strong> {doctorDetails.specialist}</p>
                        <p><strong>Video Fee:</strong> {doctorDetails.videoFee}</p>
                        <p><strong>Appointment Fee:</strong> {doctorDetails.appointmentFee}</p>
                        <p><strong>Working Hours:</strong> {doctorDetails.workinghours}</p>
                        <p><strong>Bank Name:</strong> {doctorDetails.bankName}</p>
                        <p><strong>IFSC Code:</strong> {doctorDetails.ifscCode}</p>
                        <p><strong>Account Number:</strong> {doctorDetails.accountNumber}</p>
                        <p><strong>Account Holder Name:</strong> {doctorDetails.accountHolderName}</p>
                        <button className="edit-button" onClick={() => setIsEditing(true)}>
                            Edit
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Profile;