import React, { useState, useEffect } from 'react';
import './doctor.css';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import logo from "../assets/images/logo2.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import notification from "../assets/images/Notification.png";
import { faUser, faChevronDown, faUserCircle, faCog, faSignOutAlt, faCalendarAlt, faCalendarCheck, faTimes, faInfoCircle, faShieldAlt, faFileAlt, faFileContract } from '@fortawesome/free-solid-svg-icons';
import { RxDashboard } from 'react-icons/rx';
import axios from 'axios';
import axiosURL from '../config';

function DoctorDashboard({ isLoggedIn }) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const [activeButton, setActiveButton] = useState('dashboard');
    const [notifications, setNotifications] = useState([
        "Notification 1",
        "Notification 2",
        "Notification 3"
    ]);

    const [doctorDetails, setDoctorDetails] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        // Handle background color and login status
        const checkLoginStatus = () => {
            const storedLoginStatus = localStorage.getItem('isLoggedIn');
            if (storedLoginStatus !== 'true') {
                navigate('/login');
            }
        };

        const fetchDoctorDetails = async () => {
            try {
                const doctorId = localStorage.getItem('docId');

                if (!doctorId) {
                    console.error('No doctor ID found in local storage');
                    return;
                }
                const url = `docter/getById?id=${doctorId}`;
                const response = await axiosURL.get(url);
                setDoctorDetails(response.data[0]);
            } catch (error) {
                console.error('Error fetching doctor details:', error);
            }
        };

        checkLoginStatus();
        fetchDoctorDetails();
        if (isLoggedIn) {
            document.body.style.backgroundColor = '#000';
        } else {
            document.body.style.backgroundColor = '#2a9d8f';
        }

        // Cleanup
        return () => {
            document.body.style.backgroundColor = '#ffffff';
        };
    }, [isLoggedIn, navigate]);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
        setIsNotificationOpen(false);
    };

    const handleOptionClick = (option) => {
        setIsDropdownOpen(false);
        switch (option) {
            case 'logout':
                handleLogout();
                break;
            case 'profile':
                navigate('/doctor-dashboard/profile');
                break;
            case 'about':
                navigate('/doctor-dashboard/about');
                break;
            case 'privacy':
                navigate('/doctor-dashboard/privacy');
                break;
            case 'terms&conditions':
                navigate('/doctor-dashboard/terms&conditions');
                break;
            default:
                console.warn(`Unknown option: ${option}`);
                break;
        }
    };

    const toggleNotification = () => {
        setIsNotificationOpen(!isNotificationOpen);
        setIsDropdownOpen(false);
    };

    const clearNotifications = () => {
        setNotifications([]);
    };

    const handleLogout = () => {
        localStorage.removeItem('userType');
        localStorage.removeItem('isLoggedIn');
        navigate('/login');
    };

    const today = new Date().toLocaleDateString();

    return (
        <div>
            <nav className="navbar">
                <div className="navbar-top">
                    <div className="navbar-logo">
                        <img src={logo} className="logo" alt="Logo" />
                    </div>
                    <div className="navbar-content">
                        <div className="navbar-date" style={{ fontWeight: 'bold', marginRight: '20px' }}>{today}</div>
                        <div className="navbar-icons">
                            <img src={notification} alt="Notifications" style={{ height: '36px', marginRight: '15px', cursor: 'pointer' }} onClick={toggleNotification} />
                            <div onClick={toggleDropdown} style={{ cursor: 'pointer' }}>
                                <img src={`${doctorDetails.img}`} alt="User" className="circular-image" />
                                <span className="user-name" style={{ fontWeight: 'bold', marginRight: '10px' }} >{doctorDetails.name}</span>
                                <FontAwesomeIcon
                                    icon={faChevronDown}
                                    className="dropdown-icon"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </nav>

            {isDropdownOpen && (
                <div className='dropdown'>
                    <div className="dropdown-menu">
                        <div className="dropdown-option" onClick={() => handleOptionClick('profile')}>
                            <FontAwesomeIcon icon={faUserCircle} className="dropdown-icon" />
                            <span>Profile</span>
                        </div>
                        <div className="dropdown-option" onClick={() => handleOptionClick('about')}>
                            <FontAwesomeIcon icon={faInfoCircle} className="dropdown-icon" />
                            <span>About Us</span>
                        </div>
                        <div className="dropdown-option" onClick={() => handleOptionClick('privacy')}>
                            <FontAwesomeIcon icon={faShieldAlt} className="dropdown-icon" />
                            <span>Privacy Policy</span>
                        </div>
                        <div className="dropdown-option" onClick={() => handleOptionClick('terms&conditions')}>
                            <FontAwesomeIcon icon={faFileContract} className="dropdown-icon" />
                            <span>Terms & Condition</span>
                        </div>
                        <div className="dropdown-option" onClick={() => handleOptionClick('logout')}>
                            <FontAwesomeIcon icon={faSignOutAlt} className="dropdown-icon" />
                            <span>Logout</span>
                        </div>
                    </div>
                </div>
            )}

            <nav className="navbar-bottom-green">
                <NavLink
                    to="/doctor-dashboard/dashboard"
                    className={({ isActive }) => `nav-button ${isActive ? 'active' : ''}`}
                >
                    <RxDashboard className="nav-icon" />
                    Dashboard
                </NavLink>
                <NavLink
                    to="/doctor-dashboard/schedule"
                    className={({ isActive }) => `nav-button ${isActive ? 'active' : ''}`}
                >
                    <FontAwesomeIcon icon={faCalendarAlt} className="nav-icon" />
                    Schedule
                </NavLink>
                <NavLink
                    to="/doctor-dashboard/appointment"
                    className={({ isActive }) => `nav-button ${isActive ? 'active' : ''}`}
                >
                    <FontAwesomeIcon icon={faCalendarCheck} className="nav-icon" />
                    Appointment
                </NavLink>
                <NavLink
                    to="/doctor-dashboard/payment"
                    className={({ isActive }) => `nav-button ${isActive ? 'active' : ''}`}
                >
                    <FontAwesomeIcon icon={faCalendarCheck} className="nav-icon" />
                    Payments
                </NavLink>
            </nav>

            {isNotificationOpen && (
                <div className="notification-modal">
                    <div className="modal-header">
                        <span>Notifications</span>
                        <FontAwesomeIcon
                            icon={faTimes}
                            className="close-icon"
                            onClick={toggleNotification}
                        />
                    </div>
                    <div className="modal-body">
                        {notifications.length > 0 ? (
                            notifications.map((notification, index) => (
                                <div key={index} className="notification-item">
                                    {notification}
                                </div>
                            ))
                        ) : (
                            <div>No notifications</div>
                        )}
                    </div>
                    <div className="modal-footer">
                        <button onClick={clearNotifications} className="clear-button">Clear All</button>
                    </div>
                </div>
            )}
            <div className="content">
                <Outlet />
            </div>
        </div>
    );
}

export default DoctorDashboard;