import React, { useState, useEffect, useRef } from 'react';
import './doctor.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calendarImg from "../assets/images/ScheduleCalender.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faCheckCircle, faTimesCircle, faClock, faSearch } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap';
import axiosURL from '../config';

const PaymentPage = () => {
    const [doctorId, setDoctorId] = useState(localStorage.getItem('docId') || '');
    const [startDate, setStartDate] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [activeFilterButton, setActiveFilterButton] = useState('All');
    const [orderData, setOrderData] = useState([]);
    const [modalData, setModalData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const datePickerRef = useRef(null);
    const today = new Date();

    const handleDateChange = (date) => {
        setStartDate(date);
        if (date) {
            const filtered = orderData.filter((appoint) => {
                const appointmentDate = new Date(appoint.date);
                appointmentDate.setHours(0, 0, 0, 0);
                date.setHours(0, 0, 0, 0);

                return appointmentDate.getTime() === date.getTime();
            });
            setFilteredData(filtered);
        } else {
            setFilteredData(orderData);
        }
    };

    useEffect(() => {
        fetchPayments();
    });

    const fetchPayments = async () => {
        try {
            if (!doctorId) {
                console.error('Doctor ID is not defined.');
                return;
            }
            const paymentResponse = await axiosURL.get('paymentBooking/records');
            const payments = paymentResponse.data || [];

            const filteredPayments = payments.filter(payment => payment.doctorId === doctorId);

            const userPromises = filteredPayments.map(payment =>
                axiosURL.get(`users/getById?id=${payment.userId}`)
            );
    
            const userResponses = await Promise.all(userPromises);
            
            const paymentsWithUsernames = filteredPayments.map((payment, index) => {
                const user = userResponses[index].data[0];
                return {
                    ...payment,
                    username: user.fullName
                };
            });

            setOrderData(paymentsWithUsernames);
            setFilteredData(paymentsWithUsernames);
        } catch (error) {
            console.error('Error fetching payments:', error);
        }
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    useEffect(() => {
        if (!searchTerm) {
            setFilteredData(orderData);
        } else {
            const lowercasedSearchTerm = searchTerm.toLowerCase();
            const filtered = orderData.filter(item =>
                item.username.toLowerCase().includes(lowercasedSearchTerm) || item.bookingId.toString().includes(lowercasedSearchTerm)
            );
            setFilteredData(filtered);
        }
    }, [searchTerm, orderData]);

    const getStatus = (status) => {
        switch (status) {
            case 0:
                return 'Unpaid';
            case 1:
                return 'Processing';
            case 2:
                return 'Paid';
            default:
                return 'Unknown';
        }
    };

    const handleFilterClick = (filterName) => {
        setActiveFilterButton(filterName);

        if (filterName === 'All') {
            setFilteredData(orderData);
        } else {
            const filtered = orderData.filter(appointment =>
                (filterName === 'unpaid' && appointment.status === 0) || (filterName === 'processing' && appointment.status === 1) ||
                (filterName === 'paid' && appointment.status === 2)
            );
            setFilteredData(filtered);
        }
    };

    const handleViewClick = async (id, amount, status) => {
        try {
            if (!id) {
                console.error('Booking ID is not defined.');
                return;
            }

            const response = await axiosURL.get(`docter/getbookbyid?id=${id}`);
            const orderDetails = response.data || {};

            const appointment = orderDetails.appointment || {};

            const userResponse = await axiosURL.get(`users/getById?id=${appointment.patientId}`);
            const patientName = userResponse.data[0].fullName;

            const doctorResponse = await axiosURL.get(`docter/getById?id=${appointment.doctorId}`);
            const doctorName = doctorResponse.data[0].name;

            setModalData({
                ...appointment,
                patientName,
                doctorName,
                amount,
                status
            });

            setShowModal(true);
        } catch (error) {
            console.error('Error fetching order details:', error);
        }
    };


    const clearAllFilters = () => {
        setStartDate(null);
        setActiveFilterButton('All');
        setFilteredData(orderData);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString(undefined, options);
    };

    return (
        <div className="container mt-2">
            <div className="container-fluid">
                <div className="mb-3 topHeading">
                    My <strong>Payments</strong>
                </div>
                <div className="d-flex">
                    <button
                        onClick={() => handleFilterClick('All')}
                        className={`filterBtn ${activeFilterButton === 'All' ? 'active-filter' : ''}`}
                    >
                        All
                    </button>
                    <button
                        onClick={() => handleFilterClick('paid')}
                        className={`filterBtn ${activeFilterButton === 'paid' ? 'active-filter' : ''}`}
                    >
                        Payment Done
                    </button>
                    <button
                        onClick={() => handleFilterClick('processing')}
                        className={`filterBtn ${activeFilterButton === 'processing' ? 'active-filter' : ''}`}
                    >
                        Payment Pending
                    </button>
                    <button
                        onClick={() => handleFilterClick('unpaid')}
                        className={`filterBtn ${activeFilterButton === 'unpaid' ? 'active-filter' : ''}`}
                    >
                        Payment Not Done
                    </button>
                    <div className="search-bar-container">
                        <button className="search-button">
                            <FontAwesomeIcon icon={faSearch} />
                        </button>
                        <input
                            type="text"
                            placeholder="Search..."
                            className="search-input"
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                    </div>
                    <div className="date-picker-container" style={{ marginLeft: '10px', marginRight: '15px' }}>
                        <DatePicker
                            ref={datePickerRef}
                            selected={startDate}
                            onChange={handleDateChange}
                            dateFormat="dd/MM/yyyy"
                            className="date-picker"
                            placeholderText="Search By Date"
                            minDate={today}
                        />
                        <img src={calendarImg} alt="" className="calendar-icon" onClick={() => datePickerRef.current.setOpen(true)} />
                    </div>
                    <button onClick={clearAllFilters} className="clearfilterBtn">
                        Clear Filter
                    </button>
                </div>

                <div className="PaymentTable">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Booking Id</th>
                                <th>Patient Name</th>
                                <th style={{ textAlign: 'left' }}>Date</th>
                                <th>Total Price</th>
                                <th>Amount Earned</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(filteredData && filteredData.length > 0) ? (
                                filteredData.map((order, index) => (
                                    <tr key={order.bookingId}>
                                        <td data-label="No." style={{ fontWeight: '400' }}>{index + 1}</td>
                                        <td data-label="Booking Id" style={{ fontWeight: '500' }}>{order.bookingId}</td>
                                        <td data-label="Booking Id" style={{ fontWeight: '500' }}>{order.username}</td>
                                        <td data-label="Date" style={{ fontWeight: '500', color: '#000000D2', textAlign: 'left' }}>{formatDate(order.date)}</td>
                                        <td data-label="Total Price" style={{ fontWeight: '600' }}>₹{order.totalAmount}</td>
                                        <td data-label="Amount Earned" style={{ fontWeight: '600' }}>₹{order.amountToBePaid}</td>
                                        <td data-label="Status">
                                            <div className={`status-box status-${getStatus(order.status).toLowerCase()}`}>
                                                {getStatus(order.status) === 'Paid' ? (
                                                    <FontAwesomeIcon icon={faCheckCircle} />
                                                ) : getStatus(order.status) === 'Processing' ? (
                                                    <FontAwesomeIcon icon={faClock} />
                                                ) : (
                                                    <FontAwesomeIcon icon={faTimesCircle} />
                                                )}
                                                {' '}{getStatus(order.status)}
                                            </div>
                                        </td>
                                        <td data-label="Actions">
                                            <button className="btn btn-info btn-sm" style={{ borderRadius: 10 }} onClick={() => handleViewClick(order.bookingId, order.amountToBePaid, order.status)}>
                                                <FontAwesomeIcon icon={faEye} style={{ color: '#007CD7' }} /> View
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="7">No data available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <Modal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    className="custom-modal"
                >
                    <Modal.Header>
                        <Modal.Title>Appointment Detail</Modal.Title>
                        <Button
                            className="custom-close-btn"
                            onClick={() => setShowModal(false)}
                        >
                            &times;
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <p><strong>Appointment ID:</strong> {modalData._id}</p>
                        <p><strong>Amount Earned:</strong> ₹{modalData.amount}</p>
                        <p><strong>Amount Status:</strong>
                            {modalData.status === 0 ? ' Unpaid' : modalData.status === 1 ? ' Pending' : ' Paid'}
                        </p>
                        <p><strong>Doctor Name:</strong> {modalData.doctorName}</p>
                        <p><strong>Date:</strong> {new Date(modalData.date).toLocaleDateString()}</p>
                        <p><strong>Time:</strong> {modalData.time}</p>
                        <p><strong>Patient Name:</strong> {modalData.patientName}</p>
                        <p><strong>Appointment Type:</strong> {modalData.type === 1 ? 'Clinic' : 'Video'}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => setShowModal(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

            </div>
        </div>
    );
};

export default PaymentPage;
