import React, { useState, useEffect } from 'react';
import './main.css';
import logo from './assets/images/logo2.png';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
//import Modal from 'react-modal';
import MapPicker from './components/MapPicker';
import RegisterImg from './assets/images/RegisterImg.png';
import axiosURL from './config';
import { Dialog, DialogTitle, DialogContent, DialogActions, Modal, Button, Checkbox, FormControlLabel, TextField, FormHelperText, CircularProgress, Snackbar, Alert, IconButton, Backdrop, Fade, } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { TimePicker } from '@mui/x-date-pickers';

function RegisterPage() {
    const [userType, setUserType] = useState('doctor');
    const [step, setStep] = useState(1);
    const [mediStep, setMediStep] = useState(1);
    const [docformData, setDocFormData] = useState({
        name: '',
        hospital: '',
        about: '',
        type: '',
        patients: '',
        experience: '',
        address: '',
        location: '',
        specialist: '',
        videoFee: '',
        appointmentFee: '',
        phoneNumber: '',
        bankName: '',
        accountNumber: '',
        ifscCode: '',
        accountHolderName: '',
    });
    const [mediformData, setMediFormData] = useState({
        name: '',
        phoneNumber: '',
        licenseNo: '',
        address: '',
        location: '',
        bankName: '',
        accountNumber: '',
        ifscCode: '',
        accountHolderName: '',
    })

    const [doctorImage, setDoctorImage] = useState(null);
    const [licenseImg, setLicenseImage] = useState(null);
    const [specialties, setSpecialties] = useState([]);
    const [types, setTypes] = useState([]);
    const [error, setError] = useState('');
    const [errors, setErrors] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [location, setLocation] = useState([]);
    const [loadingSpecialties, setLoadingSpecialties] = useState(true);
    const [loadingTypes, setLoadingTypes] = useState(true);
    const [isOtherSelected, setIsOtherSelected] = useState(false);
    const [otherSpecialist, setOtherSpecialist] = useState('');
    const [workingHours, setWorkingHours] = useState([]);
    const [selectedHours, setSelectedHours] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedDays, setSelectedDays] = useState([]);
    const [isWorkingModalOpen, setIsWorkingModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isVerified, setIsVerified] = useState(false);
    const [otpModalOpen, setOtpModalOpen] = useState(false);
    const [otp, setOtp] = useState('');
    const [sendingOtp, setSendingOtp] = useState(false);
    const [timer, setTimer] = useState(0);
    const [isMediVerified, setIsMediVerified] = useState(false);
    const [Mediotp, setMediOtp] = useState('');
    const [MediSendingOtp, setMediSendingOtp] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        let countdown;
        if (timer > 0) {
            countdown = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);
        }
        return () => clearInterval(countdown);
    }, [timer]);

    const handleResendOtp = async () => {
        if (timer === 0) {
            handleSubmit();
        }
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleLocationSelect = (location) => {
        const error = {};

        if (location) {
            error.location = undefined;
        }

        const latLngArray = [location[0], location[1]];

        setMediFormData((prevData) => ({
            ...prevData,
            location: latLngArray,
            address: location[2] || prevData.address,
        }));
        setLocation([location[0], location[1], location[2]]);
        setErrors((prevErrors) => ({
            ...prevErrors,
            location: error.location,
        }));
    }

    const handleClinicLocationSelect = (location) => {
        const error = {};

        if (location) {
            error.location = undefined;
        }

        const latLngArray = [location[0], location[1]];

        setDocFormData((prevData) => ({
            ...prevData,
            location: latLngArray,
            address: location[2] || prevData.address,
        }));
        setLocation([location[0], location[1], location[2]]);
        setErrors((prevErrors) => ({
            ...prevErrors,
            location: error.location,
        }));
    };


    const handleTabChange = (newValue) => {
        setUserType(newValue);
        setStep(1);
        setMediStep(1);
    };

    useEffect(() => {
        const fetchSpecialties = async () => {
            try {
                const response = await axiosURL.get('docterspec/records');
                if (response.status === 200) {
                    setSpecialties(response.data || []);
                } else {
                    console.error('Failed to fetch specialties');
                    setSpecialties([]);
                }
            } catch (error) {
                console.error('Error fetching specialties:', error);
                setSpecialties([]);
            } finally {
                setLoadingSpecialties(false);
            }
        };

        const fetchTypes = async () => {
            try {
                const response = await axiosURL.get('typeOfTreatment/records');
                if (response.status === 200) {
                    setTypes(response.data || []);
                } else {
                    console.error('Failed to fetch types');
                    setTypes([]);
                }
            } catch (error) {
                console.error('Error fetching types:', error);
                setTypes([]);
            } finally {
                setLoadingTypes(false);
            }
        };

        fetchSpecialties();
        fetchTypes();
    }, []);

    const verifyOtp = async () => {
        let apiUrl = ''
        if (userType === 'doctor') {
            apiUrl = 'docter/register';
        }
        else {
            apiUrl = 'pharma/register';
        }
        try {
            const payload = new FormData();

            if (userType === 'doctor') {
                for (const key in docformData) {
                    payload.append(key, docformData[key]);
                }
            } else {
                for (const key in mediformData) {
                    payload.append(key, mediformData[key]);
                }
            }

            if (userType === 'doctor') {
                payload.append('img', doctorImage);
            } else if (userType === 'medical') {
                payload.append('licenseImg', licenseImg);
            }

            payload.append('otp', otp);

            const response = await axiosURL.post(apiUrl, payload);
            if (response.status === 200) {
                Swal.fire('Verified', 'Profile has been sent for Approval.', 'success');
                setIsVerified(true);
                setOtpModalOpen(false);
                navigate('/pending');
            } else {
                Swal.fire('Error', 'Invalid OTP. Please try again.', 'error');
            }
        } catch (error) {
            Swal.fire('Error', 'Could not verify OTP. Please try again.', 'error');
        }
    };

    const handleDayChange = (day) => {
        setSelectedDays((prev) =>
            prev.includes(day) ? prev.filter((d) => d !== day) : [...prev, day]
        );
    };

    const handleTimeChange = (day, timeType, time) => {
        const newWorkingHours = {
            ...workingHours,
            [day]: {
                ...workingHours[day],
                [timeType]: time,
            },
        };

        if (timeType === 'endTime' && newWorkingHours[day]?.startTime && time) {
            if (time <= newWorkingHours[day].startTime) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [day]: 'End time must be greater than start time.',
                }));
                return;
            } else {
                setErrors((prevErrors) => {
                    const { [day]: removedError, ...restErrors } = prevErrors;
                    return restErrors;
                });
            }
        }

        setWorkingHours(newWorkingHours);
    };

    const handleWorkSubmit = () => {
        let valid = true;
        let errors = [];

        console.log(workingHours);

        ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].forEach(day => {
            if (selectedDays.includes(day)) {
                const startTime = workingHours[day]?.startTime;
                const endTime = workingHours[day]?.endTime;

                if (!startTime || !endTime) {
                    valid = false;
                    errors.push(`${day}: Both start and end times must be selected.`);
                }

                if (startTime && endTime && endTime <= startTime) {
                    valid = false;
                    errors.push(`${day}: End time must be greater than start time.`);
                }
            }
        });

        if (valid) {
            const daysWithHours = [];
            ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].forEach(day => {
                if (selectedDays.includes(day)) {
                    const startTime = workingHours[day]?.startTime ? workingHours[day].startTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }) : '00:00';
                    const endTime = workingHours[day]?.endTime ? workingHours[day].endTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }) : '00:00';
                    daysWithHours.push(`${day}: ${startTime} - ${endTime}`);
                }
            });

            const formattedHours = daysWithHours.join(', ');

            setDocFormData((prevData) => ({
                ...prevData,
                workinghours: formattedHours
            }));

            setIsWorkingModalOpen(false);
            setErrorMessage('');
            setErrors((prevErrors) => ({
                ...prevErrors,
                workinghours: '',
            }));
        } else {
            setErrorMessage(errors.join(' '));
        }
    };

    const validateStep1 = () => {
        const newErrors = {};

        if (!docformData.name) {
            newErrors.name = 'Name is required';
        }

        if (!docformData.phoneNumber) {
            newErrors.phoneNumber = 'Phone Number is required';
        } else if (!/^\d{10}$/.test(docformData.phoneNumber)) {
            newErrors.phoneNumber = 'Phone number must be 10 digits.';
        }

        if (!docformData.email) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(docformData.email)) {
            newErrors.email = 'Email address is invalid';
        }

        if (!docformData.hospital) {
            newErrors.hospital = 'Clinic name is required';
        }

        if (!docformData.about) {
            newErrors.about = 'Description is required';
        }

        if (!docformData.address) {
            newErrors.address = 'Clinic Address is required';
        }

        if (!docformData.location) {
            newErrors.location = 'Clinic Location is required';
        }

        if (!docformData.patients) {
            newErrors.patients = 'Number of patients is required';
        }

        if (!docformData.experience) {
            newErrors.experience = 'Experience is required';
        } else if (isNaN(docformData.experience) || parseInt(docformData.experience, 10) < 0) {
            newErrors.experience = 'Experience must be a valid number';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const validateDoctorStep2 = () => {
        const errors = {};

        if (!docformData.type) {
            errors.type = 'Select Your Type';
        }

        if (!docformData.specialist) {
            errors.specialist = 'Specialty is required';
        } else if (docformData.specialist === 'Other' && !docformData.otherSpecialist) {
            errors.specialist = 'Please specify your specialty';
        }

        if (!docformData.appointmentFee) {
            errors.appointmentFee = 'Clinic consultation fee is required';
        } else if (isNaN(docformData.appointmentFee) || parseFloat(docformData.appointmentFee) <= 0) {
            errors.appointmentFee = 'Please enter a valid fee';
        }

        if (selectedDays.length === 0) {
            errors.workinghours = 'Please select at least one working day';
        } else {
            selectedDays.forEach(day => {
                if (!workingHours[day] || !workingHours[day].startTime || !workingHours[day].endTime) {
                    errors.workinghours = `Please set working hours for ${day}`;
                }
            });
        }

        if (!doctorImage) {
            errors.doctorImage = 'Doctor image is required';
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const validateStep3 = () => {
        let newErrors = {};

        const dataToValidate = userType === 'doctor' ? docformData : mediformData;

        if (!dataToValidate.bankName || dataToValidate.bankName.trim() === '') {
            newErrors.bankName = 'Bank account name is required.';
        }

        const ifscPattern = /^[A-Za-z]{4}[a-zA-Z0-9]{7}$/;
        if (!dataToValidate.ifscCode || !ifscPattern.test(dataToValidate.ifscCode)) {
            newErrors.ifscCode = 'Invalid IFSC code. It should be 11 alphanumeric characters.';
        }

        const accountNumberPattern = /^\d{11,16}$/;
        if (!dataToValidate.accountNumber || !accountNumberPattern.test(dataToValidate.accountNumber)) {
            newErrors.accountNumber = 'Account number should be between 11 and 16 digits.';
        }

        if (!dataToValidate.accountHolderName || dataToValidate.accountHolderName.trim() === '') {
            newErrors.accountHolderName = 'Account holder name is required.';
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };


    const validateMedicalForm = () => {
        const newErrors = {};
        const { name, email, phoneNumber, licenseNo, address, location } = mediformData;

        console.log(location);

        if (!name) newErrors.mediName = 'Name is required.';
        if (!email) newErrors.mediEmail = 'Email is required.';
        if (email && !/\S+@\S+\.\S+/.test(email)) newErrors.mediEmail = 'Email is invalid.';
        if (!phoneNumber) newErrors.mediPhoneNumber = 'Phone number is required.';
        if (phoneNumber && !/^\d{10}$/.test(phoneNumber)) newErrors.mediPhoneNumber = 'Phone number must be 10 digits.';
        if (!licenseNo) newErrors.licenseNo = 'License number is required.';
        if (!address) newErrors.storeaddress = 'Store address is required.';
        if (!location) newErrors.mediLocation = 'Location is required.';
        if (!licenseImg) newErrors.licenseImg = 'License image is required.';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };



    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (userType === 'doctor') {

            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: '',
            }));

            if (name === 'specialist') {
                if (value === 'Other') {
                    setIsOtherSelected(true);
                    setDocFormData((prevData) => ({
                        ...prevData,
                        [name]: value, // Store 'Other' in specialist
                    }));
                } else {
                    setIsOtherSelected(false);
                    setDocFormData((prevData) => ({
                        ...prevData,
                        [name]: value, // Store selected specialty
                    }));
                }
            } else if (name === 'otherSpecialist') {
                setOtherSpecialist(value); // Update the custom specialty value
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    specialist: '',
                }));
            } else {
                setDocFormData((prevData) => ({
                    ...prevData,
                    [name]: value,
                }));
            }
        } else if (userType === 'medical') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: '',
            }));
            setMediFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];

        if (userType === 'doctor') {
            setDoctorImage(file);
        } else {
            setLicenseImage(file);
        }
    };

    const handleSubmit = async (e) => {
        if (e) {
            e.preventDefault();
        }
        setError('');
        setErrors({});

        const finalSpecialist = isOtherSelected ? otherSpecialist : docformData.specialist;

        let valid = true;
        let formData = {};

        if (userType === 'doctor') {
            formData = { ...docformData, specialist: finalSpecialist };

            if (!validateDoctorStep2()) {
                valid = false;
            }

            if (!doctorImage) {
                
            }
        } else if (userType === 'medical') {
            if (!validateStep3()) {
                valid = false;
            }
            formData = { ...mediformData };
            if (!licenseImg) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    licenseImg: 'License Image is required.',
                }));
                valid = false;
            }
        }

        if (!valid) {
            return;
        }

        // Determine API URL
        const apiUrl = userType === 'medical'
            ? 'pharma/register'
            : 'docter/register';

        const payload = new FormData();

        if (selectedHours.length > 0) {
            const workingHoursString = selectedHours.map(hour => hour.name).join(',');
            payload.append('workinghours', workingHoursString);
        }

        for (const key in formData) {
            if (key !== 'otherSpecialist' || isOtherSelected) {
                payload.append(key, formData[key]);
            }
        }

        if (userType === 'doctor') {
            payload.append('img', doctorImage);
        } else if (userType === 'medical') {
            payload.append('licenseImg', licenseImg);
        }

        try {
            const response = await axiosURL.post(apiUrl, payload)

            const data = response.data;
            console.log(response.data);

            if (response.status == 200) {
                setMediSendingOtp(true);
                setOtpModalOpen(true);
                Swal.fire('OTP Sent', 'Please check your phone for the OTP.', 'success');
            } else {
                const validationErrors = data.validations || [];
                const formattedErrors = validationErrors.reduce((acc, error) => {
                    acc[error.key] = error.message;
                    return acc;
                }, {});
                setErrors(formattedErrors);
            }
        } catch (error) {
            const validationErrors = error.response.data.validations || [];
            const formattedErrors = validationErrors.reduce((acc, error) => {
                acc[error.key] = error.message;
                return acc;
            }, {});
            setErrors(formattedErrors);
            Swal.fire({
                icon: 'error',
                title: error.response.data.status,
                text: error.response.data.message,
            });
        }
        finally {
            setSendingOtp(false);
        }
    };

    const renderDoctorStep1 = () => (
        <>
            <div className="register-group">
                <label>Name</label>
                <input
                    type="text"
                    name="name"
                    value={docformData.name}
                    placeholder='Enter Your Name'
                    onChange={handleInputChange}
                />
                {errors.name && <span className="error">{errors.name}</span>}
            </div>

            <div className="register-group">
                <label>Email</label>
                <input
                    type="email"
                    name="email"
                    value={docformData.email}
                    placeholder='Enter Your Email'
                    onChange={handleInputChange}
                />
                {errors.email && <span className="error">{errors.email}</span>}
            </div>

            <div className="register-group">
                <label>Phone Number</label>
                <input
                    type="text"
                    name="phoneNumber"
                    value={docformData.phoneNumber}
                    placeholder='Enter Your Phone Number'
                    maxLength={10}
                    minLength={10}
                    onChange={handleInputChange}
                    disabled={isVerified}
                    style={{ cursor: isVerified ? 'not-allowed' : 'text' }}
                />
                {errors.phoneNumber && <span className="error">{errors.phoneNumber}</span>}
            </div>

            <div className="register-group">
                <label>Clinic</label>
                <input
                    type="text"
                    name="hospital"
                    value={docformData.hospital}
                    placeholder='Enter Your Clinic Name'
                    onChange={handleInputChange}
                />
                {errors.hospital && <span className="error">{errors.hospital}</span>}
            </div>

            <div className="register-group">
                <label>Clinic Address</label>
                <div className="location-input-container">
                    <input
                        type="text"
                        name="address"
                        value={docformData.address}
                        placeholder='Enter Your Clinic Address'
                        onChange={handleInputChange}
                    />
                    <button
                        type="button"
                        className="location-picker-button1"
                        onClick={openModal}
                    >
                        <i className="fas fa-location-arrow"></i>
                    </button>
                </div>
                {errors.address && <span className="error">{errors.address}</span>}
                {errors.location && <span className="error">{errors.location}</span>}
                <Dialog
                    open={isModalOpen}
                    onClose={closeModal}
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogTitle>
                        Select Doctor Clinic Location
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={closeModal}
                            aria-label="close"
                            sx={{ position: 'absolute', right: 8, top: 8 }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <MapPicker
                            onLocationSelect={handleClinicLocationSelect}
                            onClose={closeModal}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeModal} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

            <div className="register-group">
                <label>Description</label>
                <textarea
                    name="about"
                    value={docformData.about}
                    placeholder='Describe about Yourself'
                    onChange={handleInputChange}
                />
                {errors.about && <span className="error">{errors.about}</span>}
            </div>

            <div className="register-group">
                <label>Patients</label>
                <input
                    type="text"
                    name="patients"
                    value={docformData.patients}
                    placeholder='e.g., 5000+ Patients'
                    onChange={handleInputChange}
                />
                {errors.patients && <span className="error">{errors.patients}</span>}
            </div>

            <div className="register-group">
                <label>
                    Experience <span style={{ fontSize: 'medium', fontWeight: 'normal' }}>(in Years)</span>
                </label>
                <input
                    type="text"
                    name="experience"
                    value={docformData.experience}
                    placeholder="e.g., 5 years"
                    onChange={handleInputChange}
                />
                {errors.experience && <span className="error">{errors.experience}</span>}
            </div>
        </>
    );

    const renderDoctorStep2 = () => (
        <>

            <div className="register-group">
                <label>Type</label>
                {loadingTypes ? (
                    <p>Loading types...</p>
                ) : (
                    <select
                        name="type"
                        value={docformData.type}
                        onChange={handleInputChange}
                    >
                        <option value="" disabled>Select Your Type</option>
                        {types.map((type) => (
                            <option key={type._id} value={type._id}>
                                {type.name}
                            </option>
                        ))}
                    </select>
                )}
                {errors.type && <span className="error">{errors.type}</span>}
            </div>
            <div className="register-group">
                <label>Specialist</label>
                {loadingSpecialties ? (
                    <p>Loading specialties...</p>
                ) : (
                    <select
                        name="specialist"
                        value={docformData.specialist}
                        onChange={handleInputChange}
                    >
                        <option value="" disabled>Select Your Speciality</option>
                        {specialties.map((specialty) => (
                            <option key={specialty._id} value={specialty.name}>
                                {specialty.name}
                            </option>
                        ))}
                        <option value="Other">Other</option>
                    </select>
                )}
                {errors.specialist && <span className="error">{errors.specialist}</span>}
            </div>

            {isOtherSelected && (
                <div className="register-group">
                    <label>Other Specialty</label>
                    <input
                        type="text"
                        name="otherSpecialist"
                        value={otherSpecialist}
                        placeholder='Enter Your Specialty'
                        onChange={handleInputChange}
                    />
                    {errors.specialist && <span className="error">{errors.specialist}</span>}
                </div>
            )}

            <div className="register-group">
                <label>Video Consultation Fee</label>
                <input
                    type="text"
                    name="videoFee"
                    value={docformData.videoFee}
                    placeholder='Enter Your Video Consultation Fee'
                    onChange={handleInputChange}
                />
                {errors.videoFee && <span className="error">{errors.videoFee}</span>}
            </div>
            <div className="register-group">
                <label>Appointment Fee</label>
                <input
                    type="text"
                    name="appointmentFee"
                    value={docformData.appointmentFee}
                    placeholder='Enter Your Clinic Consultation Fee'
                    onChange={handleInputChange}
                />
                {errors.appointmentFee && <span className="error">{errors.appointmentFee}</span>}
            </div>
            <div className="register-group">
                <label>Working Hours</label>
                {loading ? (
                    <div><CircularProgress /></div>
                ) : (
                    <div>
                        <Button onClick={() => setIsWorkingModalOpen(true)}>Set Working Hours</Button>
                    </div>
                )}
                {errors.workinghours && <FormHelperText error>{errors.workinghours}</FormHelperText>}
                <div style={{ marginTop: '20px' }}>
                    <h3>Current Working Hours</h3>
                    {Object.entries(workingHours).length === 0 ? (
                        <p>No working hours set</p>
                    ) : (
                        <ul>
                            {Object.entries(workingHours).map(([day, times]) => (
                                <li key={day}>
                                    {day}: {times.startTime?.toLocaleTimeString()} - {times.endTime?.toLocaleTimeString()}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                <Modal open={isWorkingModalOpen} onClose={() => setIsWorkingModalOpen(false)} style={{ overflow: 'auto' }}>
                    <div className="modal-content2">
                        <h2>Set Working Hours</h2>
                        {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day) => (
                            <div key={day}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={selectedDays.includes(day)}
                                            onChange={() => handleDayChange(day)}
                                        />
                                    }
                                    label={day}
                                />
                                {selectedDays.includes(day) && (
                                    <>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <div style={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
                                                <TimePicker
                                                    label="Start Time"
                                                    value={workingHours[day]?.startTime || null}
                                                    onChange={(time) => handleTimeChange(day, 'startTime', time)}
                                                    renderInput={(params) => <TextField {...params} />}
                                                    views={['hours', 'minutes']}
                                                    minuteStep={30}
                                                />
                                                <TimePicker
                                                    label="End Time"
                                                    value={workingHours[day]?.endTime || null}
                                                    onChange={(time) => handleTimeChange(day, 'endTime', time)}
                                                    renderInput={(params) => <TextField {...params} />}
                                                    views={['hours', 'minutes']}
                                                    minuteStep={30}
                                                />
                                            </div>
                                        </LocalizationProvider>
                                    </>
                                )}
                            </div>
                        ))}
                        <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    handleWorkSubmit();
                                }}
                            >
                                Submit
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => setIsWorkingModalOpen(false)}
                                style={{ marginLeft: '10px' }}
                            >
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>
                <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={() => setErrorMessage('')}>
                    <Alert onClose={() => setErrorMessage('')} severity="error">
                        {errorMessage}
                    </Alert>
                </Snackbar>
            </div>

            <div className="register-group">
                <label>Doctor Image</label>
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    id="doctor-image-upload"
                />
                <label htmlFor="doctor-image-upload" className="custom-file-upload">
                    Choose File
                </label>
                {doctorImage && <img src={URL.createObjectURL(doctorImage)} alt="Doctor Preview" className="image-preview" />}
                {errors.doctorImage && <span className="error">{errors.doctorImage}</span>}
            </div>

            <Modal
                aria-labelledby="otp-modal-title"
                aria-describedby="otp-modal-description"
                open={otpModalOpen}
                onClose={() => setOtpModalOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={otpModalOpen}>
                    <div className="otp-modal-content" style={{ backgroundColor: '#fff', padding: '20px', borderRadius: '8px' }}>
                        <h2 id="otp-modal-title" style={{ color: '#5c9a41' }}>Enter OTP</h2>
                        <TextField
                            id="otp-input"
                            label="OTP"
                            variant="outlined"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            fullWidth
                            InputLabelProps={{ style: { color: '#5c9a41' } }}
                            InputProps={{
                                style: { borderColor: '#5c9a41' },
                            }}
                        />
                        <div className="otp-modal-buttons" style={{ marginTop: '20px' }}>
                            <Button
                                variant="contained"
                                style={{ backgroundColor: '#5c9a41', color: '#fff', marginRight: '10px' }}
                                onClick={verifyOtp}
                            >
                                Verify OTP
                            </Button>
                            <Button
                                variant="outlined"
                                style={{ borderColor: '#5c9a41', color: '#5c9a41' }}
                                onClick={() => setOtpModalOpen(false)}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </>
    );

    const renderMedicalForm = () => (
        <>
            <div className="register-group">
                <label>Name</label>
                <input
                    type="text"
                    name="name"
                    value={mediformData.name}
                    placeholder='Enter Your Name'
                    onChange={handleInputChange}
                />
                {errors.mediName && <span className="error">{errors.mediName}</span>}
            </div>

            <div className="register-group">
                <label>Email</label>
                <input
                    type="email"
                    name="email"
                    value={mediformData.email}
                    placeholder='Enter Your Email'
                    onChange={handleInputChange}
                />
                {errors.mediEmail && <span className="error">{errors.mediEmail}</span>}
            </div>
            <div className="register-group">
                <label>Phone Number</label>
                <div className="phone-input-container">
                    <input
                        type="text"
                        name="phoneNumber"
                        value={mediformData.phoneNumber}
                        placeholder='Enter Your Phone Number'
                        maxLength={10}
                        minLength={10}
                        onChange={handleInputChange}
                        disabled={isMediVerified}
                        style={{ cursor: isMediVerified ? 'not-allowed' : 'text' }}
                    />
                </div>
                {errors.mediPhoneNumber && <span className="error">{errors.mediPhoneNumber}</span>}
            </div>
            <div className="register-group">
                <label>License Number</label>
                <input
                    type="text"
                    name="licenseNo"
                    value={mediformData.licenseNo}
                    placeholder='Enter Your License No.'
                    onChange={handleInputChange}
                />
                {errors.licenseNo && <span className="error">{errors.licenseNo}</span>}
            </div>
            <div className="register-group">
                <label>Store Address</label>
                <input
                    type="text"
                    name="address"
                    value={mediformData.address}
                    placeholder='Enter Store Address'
                    onChange={handleInputChange}
                />
                {errors.storeaddress && <span className="error">{errors.storeaddress}</span>}
            </div>
            <div className="register-group">
                <label>Location</label>
                <button
                    type="button"
                    className="location-picker-button"
                    onClick={openModal}
                >
                    <i className="fas fa-location-arrow"></i>
                </button>
                {errors.mediLocation && <span className="error">{errors.mediLocation}</span>}
                {mediformData.location && (
                    <div className="location-address">
                        <p>Store Address: {location[2]}</p>
                    </div>
                )}
                <Dialog
                    open={isModalOpen}
                    onClose={closeModal}
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogTitle>
                        Select Medical Store Location
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={closeModal}
                            aria-label="close"
                            sx={{ position: 'absolute', right: 8, top: 8 }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <MapPicker
                            onLocationSelect={handleLocationSelect}
                            onClose={closeModal}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeModal} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <div className="register-group">
                <label>License Image</label>
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    id="license-image-upload"
                />
                <label htmlFor="license-image-upload" className="custom-file-upload">
                    Choose File
                </label>
                {licenseImg && <img src={URL.createObjectURL(licenseImg)} alt="License Preview" className="image-preview" />}
                {errors.licenseImg && <span className="error">{errors.licenseImg}</span>}
            </div>
        </>
    );

    const renderBankDetailsForm = () => (
        <>
            <div className="register-group">
                <label>Bank Account Name</label>
                <input
                    type="text"
                    name="bankName"
                    value={mediformData.bankName}
                    placeholder='Enter Bank Account Name'
                    onChange={handleInputChange}
                />
                {errors.bankName && <span className="error">{errors.bankName}</span>}
            </div>

            <div className="register-group">
                <label>IFSC Code</label>
                <input
                    type="text"
                    name="ifscCode"
                    value={mediformData.ifscCode}
                    placeholder='Enter IFSC Code'
                    onChange={handleInputChange}
                />
                {errors.ifscCode && <span className="error">{errors.ifscCode}</span>}
            </div>

            <div className="register-group">
                <label>Account Number</label>
                <input
                    type="text"
                    name="accountNumber"
                    value={mediformData.accountNumber}
                    placeholder='Enter Account Number'
                    onChange={handleInputChange}
                />
                {errors.accountNumber && <span className="error">{errors.accountNumber}</span>}
            </div>

            <div className="register-group">
                <label>Account Holder Name</label>
                <input
                    type="text"
                    name="accountHolderName"
                    value={mediformData.accountHolderName}
                    placeholder='Enter Account Holder Name'
                    onChange={handleInputChange}
                />
                {errors.accountHolderName && <span className="error">{errors.accountHolderName}</span>}
            </div>
            <Modal
                aria-labelledby="otp-modal-title"
                aria-describedby="otp-modal-description"
                open={otpModalOpen}
                onClose={() => setOtpModalOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={otpModalOpen}>
                    <div className="otp-modal-content" style={{ backgroundColor: '#fff', padding: '20px', borderRadius: '8px' }}>
                        <h2 id="otp-modal-title" style={{ color: '#5c9a41' }}>Enter OTP</h2>
                        <TextField
                            id="otp-input"
                            label="OTP"
                            variant="outlined"
                            value={Mediotp}
                            onChange={(e) => setMediOtp(e.target.value)}
                            fullWidth
                            InputLabelProps={{ style: { color: '#5c9a41' } }} // Optional: color the label
                            InputProps={{
                                style: { borderColor: '#5c9a41' }, // Optional: color the border
                            }}
                        />
                        <div
                            className={`resend-otp-text ${timer > 0 ? 'disabled' : ''}`}
                            onClick={handleResendOtp}
                            style={{ cursor: timer > 0 ? 'not-allowed' : 'pointer', color: timer > 0 ? '#cccccc' : '#5c9a41', display: 'flex', justifyContent: 'flex-end' }}
                        >
                            Resend OTP {timer > 0 && `(${timer}s)`}
                        </div>
                        <div className="otp-modal-buttons" style={{ marginTop: '20px' }}>
                            <Button
                                variant="contained"
                                style={{ backgroundColor: '#5c9a41', color: '#fff', marginRight: '10px' }}
                                onClick={verifyOtp}
                            >
                                Verify OTP
                            </Button>
                            <Button
                                variant="outlined"
                                style={{ borderColor: '#5c9a41', color: '#5c9a41' }}
                                onClick={() => setOtpModalOpen(false)}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </>
    );

    return (
        <div className='main'>
            <div className='container'>
                <div className='register-container'>
                    <img src={logo} alt='Logo' className='logo' />
                    <h2>Register</h2>
                    <p style={{ color: "#00000099", marginBottom: '2rem' }}>
                        Enter your details below to create an account
                    </p>
                    <div className='register-capsule-selector'>
                        <div
                            className={`capsule ${userType === 'doctor' ? 'active' : ''}`}
                            onClick={() => handleTabChange('doctor')}
                        >
                            Doctor
                        </div>
                        <div
                            className={`capsule ${userType === 'medical' ? 'active' : ''}`}
                            onClick={() => handleTabChange('medical')}
                        >
                            Medical
                        </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                        {userType === 'doctor' ? (
                            <>
                                {step === 1 && (
                                    <>
                                        <div className="form-row">
                                            {renderDoctorStep1()}
                                        </div>
                                        <button
                                            type="button"
                                            className='register-button next-button'
                                            onClick={() => {
                                                if (validateStep1()) {
                                                    setStep(2);
                                                }
                                            }}
                                        >
                                            Next <span className="arrow">→</span>
                                        </button>
                                    </>
                                )}
                                {step === 2 && (
                                    <>
                                        <div className="form-row">
                                            {renderDoctorStep2()}
                                        </div>
                                        <button
                                            type="button"
                                            className='register-button back-button'
                                            onClick={() => setStep(1)}
                                            style={{ marginRight: '20px' }}
                                        >
                                            <span className="arrow">←</span> Back
                                        </button>
                                        <button type="submit" className='register-button'>Sign Up</button>
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                {mediStep === 1 && (
                                    <>
                                        <div className="form-row">
                                            {renderMedicalForm()}
                                        </div>
                                        <button
                                            type="button"
                                            className='register-button next-button'
                                            onClick={() => {
                                                if (validateMedicalForm()) {
                                                    setMediStep(2);
                                                }
                                            }}
                                        >
                                            Next <span className="arrow">→</span>
                                        </button>
                                    </>
                                )}
                                {mediStep === 2 && (
                                    <>
                                        <div className="form-row">
                                            {renderBankDetailsForm()}
                                        </div>
                                        <button
                                            type="button"
                                            className='register-button back-button'
                                            onClick={() => setMediStep(1)}
                                            style={{ marginRight: '20px' }}
                                        >
                                            <span className="arrow">←</span> Back
                                        </button>
                                        <button type="submit" className='register-button'>Sign Up</button>
                                    </>
                                )}
                            </>
                        )}
                        {error && <p className='error'>{error}</p>}
                    </form>
                    <hr className='custom-hr' />
                    <div className='signup-container'>
                        <p>Already have an account? <a href='/login' className='signup-link'>Login</a></p>
                    </div>
                </div>
                <div className='register-image-container'>
                    <img src={RegisterImg} alt="Uploaded Preview" className='register-image' />
                </div>
            </div>
        </div>
    );
}

export default RegisterPage;
