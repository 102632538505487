import React from 'react';

function AboutUsPage() {
    return (
        <div className="about-us-page">
            <h1>About Us</h1>
            <p>Welcome to our website. We are committed to providing the best service to our customers. Our mission is to...</p>
        </div>
    );
}

export default AboutUsPage;
