import React, { useEffect, useRef, useState } from 'react';
import './doctor.css';
import { FaPlus, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import ScheduleCard from '../components/schedule-box';
import HorImg from '../assets/images/horizontal.svg';
import VerImg from '../assets/images/vertical.svg'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calendarImg from "../assets/images/ScheduleCalender.svg";
import axios from 'axios';
import ScheduleModal from './ScheduleModal';
import axiosURL from '../config';

const Schedule = () => {
    const [doctorId, setDoctorId] = useState(localStorage.getItem('docId') || '');

    const [activeButton, setActiveButton] = useState('Button1');

    const [startDate, setStartDate] = useState(null);
    const [scheduleData, setScheduleData] = useState([]);
    const [filteredData, setFilteredData] = useState(scheduleData);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = () => setIsModalOpen(true);

    const datePickerRef = useRef(null);
    const today = new Date();

    const handleDateChange = (date) => {
        setStartDate(date);
        if (date) {
            const filtered = scheduleData.filter((schedule) => {
                const scheduleDate = new Date(schedule.date);
                scheduleDate.setHours(0, 0, 0, 0);
                date.setHours(0, 0, 0, 0);

                return scheduleDate.getTime() === date.getTime();
            });
            setFilteredData(filtered);
        } else {
            setFilteredData(scheduleData);
        }
    };


    useEffect(() => {
        if (!doctorId) {
            console.error('No doctor ID found in local storage');
            return;
        }

        fetchScheduleData();
    }, [doctorId]);

    const fetchScheduleData = async () => {
        try {
            const response = await axiosURL.get(`docter/scheduleById`, {
                params: { id: doctorId }
            });
            console.log('Schedule API response:', response.data);
            if (response.data) {
                const upcomingSchedules = response.data.filter(schedule => new Date(schedule.date).toDateString() >= today.toDateString());
                setScheduleData(upcomingSchedules);
                setFilteredData(upcomingSchedules);
            } else {
                console.error('Unexpected API response format');
            }
            console.log('d', scheduleData);
        } catch (error) {
            console.error('Failed to fetch schedule', error);
        }
    };

    const handleCloseModal = () => {
        fetchScheduleData();
        setIsModalOpen(false);
    };

    const clearAllFilters = () => {
        setStartDate(null);
        setFilteredData(scheduleData);
    };

    const handleScheduleDeleted = () => {
        fetchScheduleData();
    };

    return (
        <div className="container mt-2">
            <div className="container-fluid">
                <div className="mb-3 topHeading">
                    My <strong>Schedule</strong>
                </div>
                <div className="d-flex">
                    <button className="add-schedule-button" onClick={handleOpenModal}>
                        <FaPlus className="add-icon" />
                        Add Schedule for a Date
                    </button>
                    <div className=" date-picker-container" style={{ marginLeft: '10px', marginRight: '15px' }}>
                        <DatePicker
                            ref={datePickerRef}
                            selected={startDate}
                            onChange={handleDateChange}
                            dateFormat="dd/MM/yyyy"
                            className="date-picker"
                            placeholderText="Search By Date"
                            minDate={today}
                        />
                        <img src={calendarImg} alt="" className="calendar-icon" onClick={() => datePickerRef.current.setOpen(true)} />
                    </div>
                    <button onClick={clearAllFilters} className="clearfilterBtn">
                        Clear Filter
                    </button>
                </div>
                {isModalOpen && (
                    <ScheduleModal isOpen={isModalOpen} onClose={handleCloseModal} />
                )}
                <div>
                    <div className='mt-3 d-flex'>
                        {filteredData.map((data, index) => (
                            <>
                                <div className='mr-3'>
                                    <h1 className='headingDate'>{data.date}</h1>
                                    <div className='scroll-vertical mt-2'>
                                        {data.info.map((info, index) => (
                                            <ScheduleCard
                                                key={index}
                                                total={info.totalslots}
                                                booked={info.bookedClinic + info.bookedVideo}
                                                available={info.availableSlots}
                                                clinic={info.bookedClinic}
                                                video={info.bookedVideo}
                                                time={info.time}
                                                scheduleId={info._id}
                                                onScheduleDeleted={handleScheduleDeleted}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Schedule;
