import React from 'react';

function PrivacyPolicyPage() {
    return (
        <div className="privacy-policy-page">
            <h1>Privacy Policy</h1>
            <p>We value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and safeguard your data...</p>
        </div>
    );
}

export default PrivacyPolicyPage;
