import React from 'react';

function TermsConditionsPage() {
    return (
        <div className="terms-conditions-page">
            <h1>Terms and Conditions</h1>
            <p>By using our website, you agree to the following terms and conditions. Please read them carefully...</p>
        </div>
    );
}

export default TermsConditionsPage;
