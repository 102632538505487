import React, { useState, useEffect, useRef } from 'react';
import './doctor.css';
import AppointmentCard from '../components/appointment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calendarImg from "../assets/images/ScheduleCalender.svg";
import HorImg from '../assets/images/horizontal.svg';
import VerImg from '../assets/images/vertical.svg';
import axios from 'axios';
import axiosURL from '../config';

const Appointment = () => {
    const [doctorId, setDoctorId] = useState(localStorage.getItem('docId') || '');
    const [activeButton, setActiveButton] = useState('Button1');
    const [appointmentData, setAppointmentData] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [activeFilterButton, setActiveFilterButton] = useState('All');
    
    const datePickerRef = useRef(null);
    const today = new Date();

    const handleDateChange = (date) => {
        setStartDate(date);
        if (date) {
            const filtered = appointmentData.filter((appoint) => {
                const appointmentDate = new Date(appoint.date);
                appointmentDate.setHours(0, 0, 0, 0);
                date.setHours(0, 0, 0, 0);
    
                return appointmentDate.getTime() === date.getTime();
            });
            setFilteredData(filtered);
        } else {
            setFilteredData(appointmentData);
        }
    };
    

    useEffect(() => {
        fetchAppointmentData();
    }, []);

    const fetchAppointmentData = async () => {
        try {
            const response = await axiosURL.get('docter/appointmentById', {
                params: { id: Number(doctorId) }
            });
    
            if (Array.isArray(response.data)) {
                // Create an array to store patient data promises
                const appointmentsWithPatients = await Promise.all(
                    response.data.map(async (appointmentGroup) => {
                        const updatedInfo = await Promise.all(
                            appointmentGroup.info.map(async (info) => {
                                try {
                                    const patientResponse = await axiosURL.get('users/getById', {
                                        params: { id: info.patientId }  // Use the correct patientId from info
                                    });
                                    return { ...info, patient: patientResponse.data };
                                } catch (error) {
                                    console.error('Failed to fetch patient data', error);
                                    return { ...info, patient: null };
                                }
                            })
                        );
                        return { ...appointmentGroup, info: updatedInfo };
                    })
                );
    
                setAppointmentData(appointmentsWithPatients);
                setFilteredData(appointmentsWithPatients); // Initialize filtered data with all appointments
            } else {
                console.error('Unexpected API response format');
            }
        } catch (error) {
            console.error('Failed to fetch schedule', error);
        }
    };

    const handleClick = (buttonName) => {
        if (activeButton !== buttonName) {
            setActiveButton(buttonName);
        }
    };

    const handleFilterClick = (filterName) => {
        setActiveFilterButton(filterName);

        if (filterName === 'All') {
            setFilteredData(appointmentData);
        } else {
            const filtered = appointmentData.map(appointmentGroup => ({
                ...appointmentGroup,
                info: appointmentGroup.info.filter(appointment => 
                    (filterName == 'Clinic' && appointment.type == '1') ||
                    (filterName == 'Video' && appointment.type == '2')
                )
            })).filter(appointmentGroup => appointmentGroup.info.length > 0);
            setFilteredData(filtered);
        }
    };

    const clearAllFilters = () => {
        setStartDate(null);
        setActiveFilterButton('All');
        setFilteredData(appointmentData);
    };

    return (
        <div className="container mt-2">
            <div className="container-fluid">
                <div className="mb-3 topHeading">
                    My <strong>Appointment</strong>
                </div>
                <div className="d-flex">
                    <button 
                        onClick={() => handleFilterClick('All')} 
                        className={`filterBtn ${activeFilterButton === 'All' ? 'active-filter' : ''}`}
                    >
                        All
                    </button>
                    <button 
                        onClick={() => handleFilterClick('Clinic')} 
                        className={`filterBtn ${activeFilterButton === 'Clinic' ? 'active-filter' : ''}`}
                    >
                        Clinic Consultation
                    </button>
                    <button 
                        onClick={() => handleFilterClick('Video')} 
                        className={`filterBtn ${activeFilterButton === 'Video' ? 'active-filter' : ''}`}
                    >
                        Video Consultation
                    </button>
                    <div className="date-picker-container" style={{ marginLeft: '10px' }}>
                        <DatePicker
                            ref={datePickerRef}
                            selected={startDate}
                            onChange={handleDateChange}
                            dateFormat="dd/MM/yyyy"
                            className="date-picker"
                            placeholderText="Search By Date"
                            minDate={today}
                        />
                        <img src={calendarImg} alt="" className="calendar-icon" onClick={() => datePickerRef.current.setOpen(true)} />
                    </div>

                    <div 
                        style={{ marginLeft: '10px' }}
                        onClick={() => handleClick('Button1')}
                        className={`toggleBtn ${activeButton === 'Button1' ? 'active primary' : 'inactive'}`}
                    >
                        <img src={HorImg} className='mediumIcon' />
                    </div>
                    <div 
                        onClick={() => handleClick('Button2')}
                        className={`toggleBtn ${activeButton === 'Button2' ? 'active primary' : 'inactive'}`}
                    >
                        <img src={VerImg} className='mediumIcon' />
                    </div>
                    <button  onClick={clearAllFilters} className="clearfilterBtn">
                        Clear Filter
                    </button>
                </div>
                <div>
                    <div className={`mt-3 d-flex ${activeButton === 'Button2' ? 'flex-row' : 'flex-column'}`}>
                        {filteredData.map((data, index) => (
                            <div key={index} className='mr-3'>
                                <h1 className='headingDate'>{data.date}</h1>
                                <div className={`mt-2 ${activeButton === 'Button2' ? 'scroll-vertical' : 'scroll-horizontal'}`}>
                                    {data.info.map((appointment, index) => (
                                        <AppointmentCard
                                            key={appointment._id} // Adding a unique key for each component
                                            name={appointment.patient[0]?.fullName || 'Unknown'} // Handle missing data gracefully
                                            age={appointment.patient[0]?.ageGroup || 'N/A'} // Replace with actual age if available
                                            gender={appointment.patient[0]?.gender || 'N/A'}
                                            phone={appointment.patient[0]?.phoneNumber || 'N/A'}
                                            time={appointment.time}
                                            date={new Date(appointment.date).toLocaleDateString('en-GB')} // Format date as needed
                                            type={appointment.type} // Convert type to human-readable form
                                            history={appointment.patient[0]?.previousHistory || 'N/A'} // Replace with actual data if available
                                        />
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Appointment;
