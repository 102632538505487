import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'

import LoginPage from './Login';
import RegisterPage from './Register';

import DoctorDashboard from './Doctor-Dashboard/doctordash';
import Dashboard from './Doctor-Dashboard/dashboard';
import Schedule from './Doctor-Dashboard/schedule';
import Appointment from './Doctor-Dashboard/appointment';
import PaymentPage from './Doctor-Dashboard/Payment';
import Profile from './Doctor-Dashboard/profile';
import PrivacyPolicyPage from './Doctor-Dashboard/AboutUs/privacy';
import TermsConditionsPage from './Doctor-Dashboard/AboutUs/terms&Condition';
import AboutUsPage from './Doctor-Dashboard/AboutUs/about';

import './App.css';
import VerificationPendingPage from './Pending';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userType, setUserType] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const storedLoginStatus = localStorage.getItem('isLoggedIn');
    const storedUserType = localStorage.getItem('userType');

    if (storedLoginStatus === 'true') {
      setIsLoggedIn(true);
      setUserType(storedUserType);
    } else {
      setIsLoggedIn(false);
      setUserType('');
    }
  }, []);

  const handleLogin = (status, userType) => {
    setIsLoggedIn(status);
    setUserType(userType);
    localStorage.setItem('isLoggedIn', status.toString());
    localStorage.setItem('userType', userType);

    if (status) {
      if (userType === 'doctor') {
        navigate('/doctor-dashboard/dashboard', { replace: true });
      } else {
        navigate('/');
      }
    } else {
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('userType');
      localStorage.removeItem('docId');
      navigate('/login');
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Routes>
        <Route path="/login" element={!isLoggedIn ? <LoginPage onLogin={handleLogin} /> : <Navigate to="/doctor-dashboard/dashboard" replace />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/pending" element={<VerificationPendingPage />} />
        <Route path="/doctor-dashboard" element={isLoggedIn && userType === 'doctor' ? <DoctorDashboard /> : <Navigate to="/login" replace />}>
          <Route path="dashboard" index element={<Dashboard />} />
          <Route path="schedule" element={<Schedule />} />
          <Route path="appointment" element={<Appointment />} />
          <Route path="payment" element={<PaymentPage />} />
          <Route path="profile" element={<Profile />} />
          <Route path="about" element={<AboutUsPage />} />
          <Route path="privacy" element={<PrivacyPolicyPage />} />
          <Route path="terms&conditions" element={<TermsConditionsPage />} />
        </Route>
        <Route path="*" element={<Navigate to={isLoggedIn ? "/doctor-dashboard/dashboard" : "/login"} replace />} />
      </Routes>
    </LocalizationProvider>
  );
}

export default App;
