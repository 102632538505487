// StatusCard.js
import React from 'react';
import './component.css'
import calendarImg from "../assets/images/calender-green.svg";
import timeImg from "../assets/images/time-green.svg";
import clinicImg from '../assets/images/clinic-booking.svg';
import videoImg from '../assets/images/video-booking.svg'
import userImg from '../assets/images/user-green.svg'

const AppointmentCard = ({ name, age, gender, phone, type, date, time, history }) => {
  return (
    <div className="appointment-card">
      <div className='d-flex align-items-center'>
        <img src={userImg} className='largeIcon' />
        <div className='ml-2' style={{marginLeft:'10px'}}>
          <h1>{name}</h1>
            <div className='d-flex' style={{gap: '10px'}}>
              <h2>Age: <b style={{marginLeft:'6px'}}>{age}</b></h2>
              <h2>Gender: <b style={{marginLeft:'6px'}}>{gender}</b></h2>
              <h2>Phone: <b style={{marginLeft:'6px'}}>{phone}</b></h2>
            </div>
  
        </div>
      </div>
      <div className='d-flex align-items-center' style={{ fontWeight: '500', fontSize: 12, margin: '6px 10px 12px' }}>
        <img src={type == '2' ? videoImg : clinicImg} className='smallIcon' alt="Consultation Type Icon" />
        {type == '2' ? 'Video Consultation' : 'Clinic Appointment'}
      </div>
      <div className="green-con mt-2">
        <div className="d-flex align-items-center mr-3" style={{marginRight:'10px'}}>
          <img src={calendarImg} alt="" className="mediumIcon mr-2" style={{width: '20', marginRight:'10px'}} />
          <h1 style={{fontSize:14}}>{date}</h1>
        </div>

        <div className="d-flex align-items-center">
          <img src={timeImg} alt="" className="mediumIcon mr-2"  style={{width: '20', marginRight:'5px'}}/>
          <h1 style={{fontSize:14}}>{time}</h1>
        </div>
      </div>

      <h4>Medical History: <b>{history}</b></h4>
    </div>

  );
};

export default AppointmentCard;